import http from '@/utils/http'

export function analysisProj (p) {
  return http.post('/unibu/analysis/proj', p)
}

// 获取项目库列表
export function getProjViewList (p) {
  return http.post('/unibu/analysis/projViewPage', p)
}
