<template>
  <BaseMap ref="baseMap" defaultLayerType="tianditu" :showTools="false" style="width: 100%; min-height: 300px;"></BaseMap>
</template>

<script>
import BaseMap from '@/components/BaseMap'
import * as maptalks from 'maptalks'
import { formatLngLat } from '@/components/BaseMap/util'
export default {
  components: {
    BaseMap
  },
  props: {
    lngLat: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mapIns: null,
      layerType: '',
      markerIns: null
    }
  },
  mounted () {
    const { mapIns, layerType } = this.$refs.baseMap.init()
    this.mapIns = mapIns
    this.layerType = layerType
  },
  watch: {
    lngLat (lngLat) {
      if (lngLat && this.mapIns) {
        this.setActiveComp(lngLat)
      }
    }
  },
  methods: {
    setActiveComp (lngLat) {
      if (!lngLat) return

      const position = formatLngLat(lngLat.split(','), 'gps', this.layerType)
      this.mapIns.setCenter(position)
      this.mapIns.setZoom(16)
      let point
      if (this.markerIns) {
        point = this.markerIns
      } else {
        point = this.markerIns = new maptalks.Marker(
          position,
          {
            symbol: {
              markerFile: require('@/assets/imgs/common/marker_icon.png'),
              markerWidth: 20,
              markerHeight: 20,
              markerDy: 0
            }
          }
        )
        new maptalks.VectorLayer('vector', point).addTo(this.mapIns)
        point.animate(
          {
            symbol: { markerDy: 5 }
          },
          {
            duration: 1000,
            easing: 'upAndDown',
            repeat: true
          }
        )
      }

      point.setCoordinates(position)
    }
  }
}
</script>

<style>

</style>
