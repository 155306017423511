<template>
  <div class="h-100-p relative" style="background: #021521;">
    <BaseMap
      ref="myMap"
      defaultLayerType="tianditu"
      :showTools="false"
      :minZoom="9"
      :maxZoom="13"
      :zoom="12"
      style="width: 100%; height: 100%;"
    ></BaseMap>
    <div class="left-pane flex flex-column flex-nowrap" style="color:white; position: absolute; top: 20px; height: calc(100% - 150px); left: 20px; z-index: 9;">
      <div v-if="showSearchbar" class="search-tab">
        <!-- 日期 -->
        <div class="flex flex-nowrap align-center">
          <div class="fs-14 ml-5 flex-shrink mt-15 mr-5" style="align-self:flex-start;line-height: 32px;">日期：</div>
          <div class="search-options flex mt-10 mb-10">
            <div
              @click="timeActId = item.id"
              v-for="item in timeOptions"
              :key="item.id"
              :class="{active: timeActId == item.id}"
              class="search-option-item pointer mt-5 mb-5"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="hr"></div>
        <!-- 距离 -->
        <div class="flex flex-nowrap align-center">
          <div class="fs-14 ml-5 flex-shrink mt-15 mr-5" style="align-self:flex-start;line-height: 32px;">距离：</div>
          <div class="search-options flex mt-10 mb-10">
            <div
              @click="distanceActId = item.id"
              v-for="item in distanceOptions"
              :key="item.id"
              :class="{active: distanceActId == item.id}"
              class="search-option-item pointer mt-5 mb-5"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="hr"></div>

        <!-- 面积 -->
        <div class="flex flex-nowrap align-center">
          <div class="fs-14 ml-5 flex-shrink mt-15 mr-5" style="align-self:flex-start;line-height: 32px;">面积：</div>
          <div class="search-options flex mt-10 mb-10">
            <div
              @click="areaActId = item.id"
              v-for="item in areaOptions"
              :key="item.id"
              :class="{active: areaActId == item.id}"
              class="search-option-item pointer mt-5 mb-5"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="hr"></div>
        <!-- 类型 -->
        <div class="flex flex-nowrap align-center">
          <div class="fs-14 ml-5 flex-shrink mt-15 mr-5" style="align-self:flex-start;line-height: 32px;">类型：</div>
          <div class="search-options flex">
            <div
              @click="projActId = item.id"
              v-for="item in projTypeOptions"
              :key="item.id"
              :class="{active: projActId == item.id}"
              class="search-option-item pointer mt-15 mb-15"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="flex justify-end mt-10">
          <el-button type="primary" size="small" @click="onSearch">搜索</el-button>
          <div class="fs-14 pointer" @click="showSearchbar=false" style="color: #00FFFF;line-height: 32px;">
            <i class="el-icon-d-arrow-left ml-10 mr-5" style="transform:rotate(90deg)"></i>
            <span>收起</span>
          </div>
        </div>
      </div>
      <div v-else @click="showSearchbar=true" class="search-tab flex align-center justify-center pointer" style="color: #00FFFF;">
        筛选条件
        <i class="el-icon-d-arrow-left ml-10" style="transform:rotate(-90deg)"></i>
      </div>

      <div class="company-list mt-10 flex-1 custom-scrollbar" style="overflow:hidden;">
        <div class="flex">
          <p class="fs-16 flex-1 flex align-center">项目列表</p>
          <!-- <el-button size="small" type="primary" @click="handleExport">导出列表</el-button> -->
          <el-button size="small" type="primary" @click="$router.push('/infoManage/projectManage')">切换回列表</el-button>
        </div>
        <div class="hr mt-10"></div>
        <ResizeWrap
          v-loading="loading"
          class="mt-20 fs-14 flex flex-column flex-nowrap"
          style="width: 330px; box-sizing: border-box; height: calc(100% - 88px); overflow-x: hidden; overflow-y: auto;"
        >
          <template v-slot="{height}">
            <div v-if="comps.length === 0">暂无数据</div>

            <vxe-list
              v-else
              ref="myList"
              :height="height+'px'"
              :data="comps"
              :scroll-y="{gt:0,oSize:50}"
              :auto-resize="true">
              <template #default="{ items }">
                <div
                  @click="activeMarker(item)"
                  class="ml-10 mb-10 flex pointer"
                  style="line-height: 32px; color: rgba(255, 255, 255, .8)"
                  v-for="item in items"
                  :key="item.jzscElecEvidId">
                  <i v-if="item.jzscElecEvidId == activeComp.jzscElecEvidId" class="iconfont icon-danxuananniu" style="color: #00FFFF;"></i>
                  <i v-else class="iconfont icon-ico2" style="color: #00FFFF;opacity: .4;"></i>
                  <div class="ml-10 mr-10 flex-1 text-1line" :title="item.projname||'无'">{{item.projname||'无'}}</div>
                </div>
              </template>
            </vxe-list>
          </template>

        </ResizeWrap>
        <div v-if="comps.length !== 0" class="fs-12 flex align-center justify-center" style="height:30px;opacity: .8;">共 {{comps.length}}条 记录</div>
      </div>
    </div>

    <div
      class="total-card flex justify-around"
      v-if="totalView.length > 0"
      style="width: calc(100% - 760px); position: absolute; top: 20px; left: 50%; transform: translateX(-50%); z-index: 9;">
      <div
        v-for="item in totalView"
        :key="item.name"
        class="total-item flex flex-column align-center"
      >
        <div class="total-title">{{item.name}}</div>
        <CountToCard class="pt-11 mb-10" :value="item.value" />
      </div>
    </div>

    <div class="simple-actions" style="position: absolute; bottom: 120px; left: 50%; transform: translateX(-50%); z-index: 9;">
      <img class="pointer simple-item" v-animate src="@/assets/imgs/dataView/proj1.png" @click="changeParams('timeActId', 3)"/>
      <img class="pointer simple-item" v-animate src="@/assets/imgs/dataView/proj2.png" @click="changeParams('distanceActId', 10)"/>
      <img class="pointer simple-item" v-animate src="@/assets/imgs/dataView/proj3.png" @click="changeParams('projActId', 2)"/>
      <img class="pointer simple-item" v-animate src="@/assets/imgs/dataView/proj4.png" @click="changeParams('areaActId', 3)"/>
    </div>

    <div class="right-pane" style="position: absolute; top: 20px; right: 20px; z-index: 9;">
      <div v-if="activeComp" class="company-detail">
        <p class="mb-15 flex justify-between" style="color: rgba(0, 255, 255, .9);line-height: 1.2;">{{activeComp.projname}}</p>
        <p class="fs-14" style="color: rgba(255, 255, 255, .85);line-height: 1.2;margin: 12px 0;">建设单位：{{activeComp.projowner||'无'}}</p>
        <p class="fs-14" style="line-height: 42px;color: rgba(255, 255, 255, .85);">类型：{{activeComp.prjtypenum}}</p>
        <!-- 面积 -->
        <p class="fs-14" style="line-height: 42px;color: rgba(255, 255, 255, .85);">{{activeComp.projscale&&activeComp.projscale.includes('面积')?activeComp.projscale: '面积：-'}}</p>
        <PositionMap class="mt-10" style="height: 300px" :lngLat="getLngLat"></PositionMap>
      </div>
    </div>
  </div>
</template>

<script>
import { analysisProj, getProjViewList } from '@/apis/dataView/projectView'
import BaseMap from '@/components/BaseMap'
import * as maptalks from 'maptalks'
import { ClusterLayer } from 'maptalks.markercluster'
import { formatLngLat } from '@/components/BaseMap/util'
import CountToCard from '@/components/CountToCard'
import axios from 'axios'
import PositionMap from './PositionMap'

export default {
  components: {
    BaseMap,
    PositionMap,
    CountToCard
  },
  computed: {
    getLngLat () {
      return this.activeComp?.lngLat
    }
  },
  data () {
    return {
      showSearchbar: false,
      loading: false,
      timeActId: -1,
      distanceActId: -1,
      areaActId: -1,
      projActId: 1,
      timeOptions: [
        { id: -1, name: '全部' },
        { id: 3, name: '最近3天' },
        { id: 7, name: '最近7天' },
        { id: 30, name: '最近30天' }
      ],
      distanceOptions: [
        { id: -1, name: '全部' },
        { id: 10, name: '近10公里' },
        { id: 20, name: '近20公里' },
        { id: 30, name: '近30公里' }
      ],
      areaOptions: [
        { id: -1, name: '全部' },
        { id: 1, name: '小型项目' },
        { id: 2, name: '中型项目' },
        { id: 3, name: '大型项目' }
      ],
      projTypeOptions: [
        { id: 1, name: '全部项目' },
        { id: 2, name: '市政项目' }
      ],
      mapIns: null,
      layerType: '',
      projectList: [],
      compName: '',
      comps: [],
      markers: [],
      iconType: 'normal', // marker icon
      icons: {
        normal: require('@/assets/imgs/dataView/proj_icon6.png'),
        timeActId: require('@/assets/imgs/dataView/proj_icon1.png'),
        distanceActId: require('@/assets/imgs/dataView/proj_icon3.png'),
        projActId: require('@/assets/imgs/dataView/proj_icon4.png'),
        areaActId: require('@/assets/imgs/dataView/proj_icon2.png'),
        active: require('@/assets/imgs/dataView/proj_icon5.png')
      },
      usefulComps: [],
      positions: [
        { id: 1, name: '普通工程', value: [] },
        { id: 2, name: '重点工程', value: [] }
      ],
      activeComp: {},
      totalView: [
        { name: '在建项目数', value: 0, prop: 'total' },
        { name: '本年新增项目数', value: 0, prop: 'yearTotal' },
        { name: '本月新增项目数', value: 0, prop: 'monthTotal' },
        { name: '今日新增项目数', value: 0, prop: 'dayTotal' },
        { name: '本年新开工面积(平方米)', value: 0, prop: 'area' },
        { name: '本年新投资额(万元)', value: 0, prop: 'price' }
      ],
      // 围栏
      polygons: []
    }
  },
  mounted () {
    const { mapIns, layerType } = this.$refs.myMap.init()
    this.mapIns = mapIns
    this.$refs.myMap.enableGrayFilter()
    this.layerType = layerType
    this.clusterLayer = new maptalks.VectorLayer('vector', null, {
      zIndex: 1
    }).addTo(mapIns)

    this.initList()

    const userInfo = this.$store.state.account.userInfo
    const code = userInfo.regiCountrCode || userInfo.regiCityCode || userInfo.regiProvinCode || 100000
    axios.get('/map/' + code + '.json').then((res) => {
      const features = res.data.features

      features.forEach((g, i) => {
        const coordinates = g.geometry.coordinates
        const properties = g.properties
        this.drawPolygons(i, coordinates, properties)
      })

      new maptalks.VectorLayer(
        'vector-polygon',
        this.polygons
      ).addTo(this.mapIns)
    })

    this.initTotalView()
  },
  methods: {
    onSearch () {
      this.iconType = 'normal'
      this.initList()
    },
    changeParams (propName, value) {
      this.iconType = propName
      this.timeActId = -1
      this.distanceActId = -1
      this.areaActId = -1
      this.projActId = 1
      this[propName] = value
      this.initList()
    },

    getParams () {
      const paramObj = {}
      // 面积：大/中/小型项目
      if (this.areaActId === -1) {
        paramObj.min = true
        paramObj.mid = true
        paramObj.big = true
      } else {
        paramObj.min = this.areaActId == 1
        paramObj.mid = this.areaActId == 2
        paramObj.big = this.areaActId == 3
      }
      // 日期
      paramObj.day = this.timeActId == -1 ? undefined : this.timeActId
      // 市政项目
      paramObj.government = this.projActId == 2
      // 距离
      paramObj.range = this.distanceActId == -1 ? undefined : this.distanceActId
      return paramObj
    },
    // 单选项目：
    // 1. 激活效果
    // 2. marker变色，闪动，移动到marker位置
    activeMarker (projectObj) {
      if (this.activeComp == projectObj && this.activeComp.jzscElecEvidId == projectObj.jzscElecEvidId) return
      const marker = this.markers.find(item => item.getId() == projectObj.jzscElecEvidId)

      if (this.activeComp.jzscElecEvidId) {
        const preMarker = this.markers.find(item => item.getId() == this.activeComp.jzscElecEvidId)
        if (preMarker) {
          preMarker.updateSymbol([{
            markerFile: this.icons[this.iconType] // marker 图标
          }])
        }
      }

      this.activeComp = projectObj
      if (marker) {
        const center = marker.getCoordinates()
        marker.bringToFront()
        marker.getMap().animateTo({
          center,
          zoom: 11
        })
        marker.flash(200, 3)
        marker.updateSymbol([{
          markerFile: this.icons.active // marker 图标
        }])
      } else {
        this.$notify.error({ title: '失败', message: '无位置信息' })
      }
    },

    drawPolygons (idx, coordinates, properties) {
      const polygon = new maptalks.MultiPolygon(coordinates[0], {
        symbol: {
          lineWidth: 1,
          lineColor: '#00ffff',
          polygonFill: '#00FFFF',
          polygonOpacity: 0.2
        },
        properties: {
          id: properties.id,
          index: idx,
          properties: properties
        }
      })

      polygon.on('mouseenter', (e) => {
        e.target.updateSymbol({
          // polygonFill: ''
        })
      })
      polygon.on('mouseout', (e) => {
        e.target.updateSymbol({
          // polygonFill: ''
        })
      })
      this.polygons.push(polygon)
    },
    handleExport () {

    },
    async initTotalView () {
      const res = await analysisProj()
      if (res.code == 200) {
        const data = res.data || {}
        this.totalView = this.totalView.map((item) => {
          const value = data[item.prop] || 0
          item.value = value
          return item
        })
      } else {
        this.$notify.error({ title: '失败', message: res.msg })
      }
    },
    filterComp () {
      const selectedIds = this.selectedOptions
      this.usefulComps = this.comps.filter(item => {
        const areaIds = [item.regiProvinId, item.regiCityId, item.regiCountrId].filter(areaId => areaId)
        const includeArea = selectedIds.every((areaId) => areaIds.includes(areaId))
        return item.projName.includes(this.compName) && includeArea
      })
    },

    getMarkerIns (node) {
      if (!node.lngLat) return
      const [lng, lat] = node.lngLat.split(',').map(item => Number(item))
      const lngLat = formatLngLat(
        [lng, lat],
        'gps',
        this.layerType
      )
      return new maptalks.Marker(lngLat, {
        id: node.jzscElecEvidId, // 唯一标识 pointId
        properties: {
          name: node.projname,
          lng: lng,
          lat: lat
        },
        symbol: [
          {
            markerFile: this.icons[this.iconType], // marker 图标
            // markerWidth: 25,
            markerHeight: 25,
            markerHorizontalAlignment: 'middle',
            markerVerticalAlignment: 'top'
          }
        ]
      })
    },

    // 创建marker图层添加到地图实例中
    createMarkersLayerToMap (markers, mapIns) {
      const layer = mapIns.getLayer('cluster')
      if (layer) {
        mapIns.removeLayer('cluster')
      }
      const newLayer = new ClusterLayer('cluster', markers, {
        noClusterWithOneMarker: true,
        maxClusterZoom: 10, // 聚合
        // maxClusterRadius: 65,
        zIndex: 9,
        symbol: {
          markerType: 'ellipse',
          markerFill: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 'rgb(135, 196, 240)'],
              [9, '#1bbc9b'],
              [99, 'rgb(216, 115, 149)']
            ]
          },
          markerFillOpacity: 0.8,
          markerLineOpacity: 1,
          markerLineWidth: 3,
          markerLineColor: '#fff',
          markerWidth: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          },
          markerHeight: {
            property: 'count',
            type: 'interval',
            stops: [
              [0, 30],
              [9, 40],
              [99, 55]
            ]
          }
        },
        drawClusterText: true,
        geometryEvents: true,
        single: true,
        animation: false
      })
      this.$nextTick(() => {
        mapIns.addLayer(newLayer)
      })
    },

    getTextWidth (text, font = 'normal 12px sans-serif') {
      const canvas = this.textCanvas || (this.textCanvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    },

    async initList () {
      if (this.loading) return
      this.loading = true
      const markers = this.markers = []
      const params = this.getParams()
      const res = await getProjViewList({ ...params, pageNum: 1, pageSize: 9999 })

      if (res.code == 200) {
        this.comps = res.data.result.rows.map(item => {
          const splitStr = item.locationx && item.locationy ? ',' : ''
          item.lngLat = (item.locationx || '') + splitStr + (item.locationy || '')
          const markerIns = this.getMarkerIns(item)

          if (markerIns) {
            markerIns.on('click', () => {
              let index = 0
              const obj = this.comps.find((item, i) => {
                if (item.jzscElecEvidId == markerIns.getId()) {
                  index = i
                  return true
                } else {
                  return false
                }
              })
              if (obj) {
                this.$refs.myList.scrollTo(0, index * 42)
                this.activeMarker(obj)
              }
            })
            markers.push(markerIns)
          }
          return item
        })

        this.usefulComps = this.comps
        this.createMarkersLayerToMap(markers, this.mapIns)

        // 激活第一个项目
        if (this.comps[0]) {
          setTimeout(() => {
            this.activeMarker(this.comps[0])
          }, 14)
        }
      } else {
        this.$notify.error({ title: '失败', message: res.msg })
      }
      this.loading = false
    }

  }
}
</script>

<style lang="scss" scoped>
.search-tab,
.company-list,
.company-detail {
  width: 350px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #0B4856;
  background: #021521;
}
.company-detail {
  width: 300px;
}
.search-tab {
  padding: 10px 15px;
  .search-options {
    width: 330px;
    margin-left: -10px;
  }
  .search-option-item {
    box-sizing: border-box;
    font-size: 14px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #0B4856;
    text-align: center;
    margin-left: 10px;
    &.active {
      border-color: #00FFFF;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        border: 2px solid #00FFFF;
        border-color: #00FFFF transparent transparent #00FFFF;
      }
    }
  }
}
.hr {
  border-bottom: 1px solid #0B4856;
}
.total-card {
  z-index: 9;
  .total-title {
    margin: 0 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
}
::v-deep {
  .el-loading-mask {
    background: rgba(0, 0, 0, .45);
  }
  .el-loading-spinner .path{
    stroke: #26FFF8!important;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #00FFFF;
    background: #00FFFF;
  }
  .el-radio__inner::after {
    background-color: #000;
  }
  .el-button--primary {
    border-color: #05A055;
    background-color: #05A055;
    border-radius: 0;
    &:hover, &:focus {
      border-color: #05A055;
      background-color: #05A055;
    }
    &:hover {
      opacity: .8;
    }
  }
}
// 滚动条部分
.custom-scrollbar {
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /*滚动条的轨道*/
  ::-webkit-scrollbar-track {
    background-color: #021521;
  }
  /*滚动条里面的小方块，能向上向下移动*/
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144,147,153,.5);
    border-radius: 5px;
    border: 1px solid #01808B;
    // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }
  ::-webkit-scrollbar-thumb:hover {
    // background-color: #A8A8A8;
    background-color: rgba(144,147,153,.4);
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(144,147,153,.5);
  }
  /*边角，即两个滚动条的交汇处*/
  ::-webkit-scrollbar-corner {
    background-color: #01808B;
  }
}
.simple-item {
  width: 130px;
  margin: 20px;
}

</style>
